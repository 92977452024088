import React from "react";
import teamData from "../Components/Teamdata";

const ContactUs = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full  grid grid-cols-1 md:grid-cols-2">
        <div className="bg-[#00183A] text-white p-8">
          <h1 className="text-6xl font-bold">
            <span className="text-[#F7AE3A]">OUR</span> TEAM
          </h1>
          <p className="mt-4">
            At Continental Intertrade Limited, we are a dynamic and diverse team
            of African entrepreneurs united by a shared vision to connect the
            continent to the world through international trade. Our team
            comprises experienced professionals from various backgrounds,
            including trade, manufacturing, agriculture, technology, and
            consumer goods.
          </p>
          <ul className="mt-8 space-y-4">
            <li>
              <span className="font-bold text-[#F7AE3A]">
                African Roots, Global Reach:
              </span>{" "}
              As a team of African entrepreneurs, we have a deep understanding
              of the continent’s unique opportunities and challenges. Our global
              network and expertise enable us to facilitate trade across
              different regions.
            </li>
            <li>
              <span className="font-bold text-[#F7AE3A]">
                Multidisciplinary Expertise:
              </span>{" "}
              Our team’s diverse background and experience in various sectors
              allow us to provide comprehensive solutions to boost the African
              economy.
            </li>
            <li>
              <span className="font-bold text-[#F7AE3A]">
                Passionate and Innovative:
              </span>{" "}
              We are driven by a passion for Africa’s economic growth and
              development. Our innovative approach to international trade
              enables us to stay ahead of the curve and find new opportunities
              for our Africa.
            </li>
          </ul>
        </div>

        
        <div className="bg-white p-8 grid grid-cols-1 sm:grid-cols-2 gap-8">
          {teamData.map((member) => (
            <div className="flex flex-col items-center text-center">
              <img
                className={member.className}
                src={member.image}
                alt={member.name}
              />
              <h2 className="text-xl font-bold">{member.name}</h2>
              <p className="text-sm text-gray-600">{member.country}</p>
              <img
                className="w-8 h-8 mt-2"
                src={member.flag}
                alt={`${member.country} flag`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
