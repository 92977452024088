import logo from './logo.svg';
import './App.css';
import MainNavigation from './Navigation/MainNavigation';

function App() {
  return (
    <MainNavigation />
  );
}

export default App;
