const teamData = [
  {
    name: 'Charles Olufemi Folayan',
    country: 'Nigeria',
    flag: require('../Images/nigeria.png'),
    image: require('../Images/teamPic4.png'),
    className: "w-36 h-40"
  },
  {
    name: 'Zakaria El-Hamel',
    country: 'Morocco',
    flag: require('../Images/morroco.png'),
    image: require('../Images/teamPic7.png'),
    className: "w-58 h-40"

  },
  {
    name: 'Eve Mathai',
    country: 'Kenya',
    flag: require('../Images/kenya.png'),
    image: require('../Images/teampic1.png'),
    className: "w-58 h-40"
  },
  {
    name: 'Mawuli Komla Kotoh',
    country: 'Ghana',
    flag: require('../Images/ghana.png'),
    image: require('../Images/Team1.png'),
    className: "w-58 h-40"

  },
  {
    name: 'Hyacinthe Mesmin Pameessoua',
    country: 'Central Africa Republic',
    flag: require('../Images/centralAfrica.png'),
    image: require('../Images/teamPic3.png'),
    className: "w-58 h-40"

  },
  {
    name: 'Princess Ann Ehigiator',
    country: 'Nigeria',
    flag: require('../Images/nigeria.png'),
    image: require('../Images/teamPic6.png'),
    className: "w-58 h-40"

  },
  {
    name: 'Ms. Innocent Tshilanda',
    country: 'Democratic Republic of Congo',
    flag: require('../Images/congo.png'),
    image: require('../Images/teamPic5.png'),
    className: "w-58 h-40"

  },
  {
    name: 'Blessing Arinitwe',
    country: 'Uganda',
    flag: require('../Images/uganda.png'),
    image: require('../Images/teamPic2.png'),
    className: "w-58 h-40"

  },
];

export default teamData;
