import React from "react";
import Topimage from "../Images/Topimage1.png"; // Make sure to replace this with the correct path
import backgroundBuilding from "../Images/buildingimage1.png"; // Make sure to replace this with the correct path
import NationFlag from "../Images/NationFlag.png"; // Make sure to replace this with the correct path
import aboutImage from "../Images/Maninsuit.png";
import spacedImage1 from "../Images/Basketman.png";
import spacedImage2 from "../Images/Vegetablewoman.png";
import spacedImage3 from "../Images/Engineer.png";
import rightBigImage from "../Images/Logodesign.png";
import visionImage from "../Images/Blackman.png"; // Replace with the actual path to your image
import missionImage from "../Images/Engineerwithtape.png";
import TopImage2 from "../Images/peopleImage2.png";
import Analysis from "../Images/Analysis.png";

import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div className="relative h-screen bg-blue-900 text-white">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <img
          src={backgroundBuilding}
          alt="Cover"
          className="w-full h-full object-cover absolute mix-blend-overlay"
        />
        <div className="relative z-10 p-8 flex flex-col items-start justify-center h-full">
          <div className="flex items-start">
            <div className="p-2">
              <img
                src={Topimage}
                alt="Africa Map"
                className="w-full h-auto max-w-[550px]"
              />
              <Link to="/contact">
                <button className="text-[#00183A] bg-white py-3 px-10 rounded-md hover:bg-[#00183A] hover:text-white transition duration-300 ease-in-out transform hover:scale-105">
                  <span>Our team</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="md:min-h-screen flex flex-col md:flex-row bg-[#00083A] py-12 md:py-0">
        {/* Left Side */}
        <div className="md:w-1/2 p-4">
          <div className="relative h-1/3 mb-4">
            <img
              src={aboutImage}
              alt="About Company"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-[#00183A] opacity-70 flex flex-col items-center justify-center text-center">
              <span
                className="text-4xl md:text-5xl font-bold"
                style={{ color: "#F7AE3A" }}
              >
                <b>ABOUT </b>
              </span>
              <br />
              <span
                className="text-4xl md:text-5xl font-bold"
                style={{ color: "white" }}
              >
                <b>COMPANY</b>
              </span>
            </div>
          </div>

          <div className="h-1/3 mb-4">
            <span className="text-md text-white px-4 md:px-16 block">
              Continental Intertrade Limited is a multinational company formed
              by a team of African Entrepreneurs, engaged in various aspects of
              international trade, including global trade networking, importing,
              exporting, and distribution of goods across different regions. It
              operates in diverse sectors such as manufacturing, blue economy,
              agriculture, renewable energy, technology and consumer goods
            </span>
          </div>

          <div className="h-1/3 flex justify-around mx-4">
            <img
              src={spacedImage1}
              alt="Spaced 1"
              className="w-1/4 h-1/2 md:w-2/5 md:h-3/5 object-cover"
            />
            <img
              src={spacedImage2}
              alt="Spaced 2"
              className="w-1/4 h-1/2 md:w-2/5 md:h-3/5 object-cover mx-2"
            />
            <img
              src={spacedImage3}
              alt="Spaced 3"
              className="w-1/4 h-1/2 md:w-2/5 md:h-3/5 object-cover"
            />
          </div>
        </div>

        <div className="md:w-1/2 bg-[#F7AE3A] flex items-center justify-center p-4">
          <img
            src={rightBigImage}
            alt="Right Big"
            className="w-4/5 md:w-3/4 md:h-3/4 object-cover"
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:h-screen">
        <div className="w-full md:w-1/2 bg-[#F7AE3A] p-8 flex flex-col justify-between">
          <div>
            <h2 className="text-4xl md:text-5xl text-center font-bold text-white my-8 mb-14">
              <span className="text-[#08285A]">OUR</span> VISION
            </h2>
            <p className="mt-2 text-lg flex flex-row">
              {" "}
              <span className="text-4xl font-bold p-6 text-[#08285A]">
                01
              </span>{" "}
              <span className="flex flex-col justify-center text-white">
                {" "}
                To create opportunities for the diversity of our resources to
                match our manpower and economic development.
              </span>
            </p>
          </div>
          <img
            src={missionImage}
            alt="Vision"
            className="mt-4 w-4/5 self-center"
          />
        </div>
        <div className="w-full md:w-1/2 bg-[#08285A] p-8 flex flex-col justify-between">
          <div>
            <h2 className="text-4xl md:text-5xl text-center font-bold text-white my-8 mb-14">
              <span className="text-[#F7AE3A]">OUR</span> MISSION
            </h2>
            <p className="mt-2 text-lg flex flex-row justify-center">
              {" "}
              <span className="text-4xl font-bold p-6 text-[#F7AE3A]">
                02
              </span>{" "}
              <span className="flex flex-col justify-center text-white">
                Leveraging on Partnership to Accelerate Inclusive Growth and
                Sustainable Development.
              </span>
            </p>
          </div>
          <img
            src={visionImage}
            alt="Mission"
            className="mt-4 w-4/5 self-center"
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-between bg-white">
        {/* Left Section */}
        <div className="relative lg:w-1/2 flex flex-col items-center lg:items-start lg:text-left sm:h-screen p-8">
          <div className="flex flex-col lg:items-start">
            <h1 className="text-yellow-500 text-5xl text-end w-full flex-row font-bold mb-4">
              OUR GOALS
            </h1>
            <img
              src={Analysis}
              alt="Goals"
              className=" lg:w-full h-full mb-4"
            />
          </div>
        </div>

        {/* Right Section */}
        <div className="relative lg:w-1/2 bg-[#00183A] text-white p-8 lg:h-screen ">
          {/* <h2 className="text-5xl font-bold mb-6">GOALS</h2> */}
          <div className="relative">
            <ul className="space-y-12">
              <li className="flex items-start">
                {/* <div className="absolute left-0 h-full border-l-2 border-yellow-500" style={{ top: '25px', bottom: '25px' }}></div> */}
                <div className="relative">
                  <div className="absolute w-4 h-4 bg-yellow-500 rounded-full -left-6 top-0"></div>
                </div>
                <div className="pl-8">
                  <h3 className="text-yellow-500 text-2xl font-semibold">
                    CONNECTING
                  </h3>
                  <p>
                    To connect emerging African businesses and facilitate
                    partnerships for development.
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="relative">
                  <div className="absolute w-4 h-4 bg-yellow-500 rounded-full -left-6 top-0"></div>
                </div>
                <div className="pl-8">
                  <h3 className="text-yellow-500 text-2xl font-semibold">
                    NETWORKING
                  </h3>
                  <p>
                    To facilitate networking opportunities among African
                    businesses, policymakers, and investors.
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="relative">
                  <div className="absolute w-4 h-4 bg-yellow-500 rounded-full -left-6 top-0"></div>
                </div>
                <div className="pl-8">
                  <h3 className="text-yellow-500 text-2xl font-semibold">
                    KNOWLEDGE SHARING
                  </h3>
                  <p>
                    To enhance knowledge sharing on trade regulations, market
                    trends, and business opportunities.
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="relative">
                  <div className="absolute w-4 h-4 bg-yellow-500 rounded-full -left-6 top-0"></div>
                </div>
                <div className="pl-8">
                  <h3 className="text-yellow-500 text-2xl font-semibold">
                    PUBLIC AND PRIVATE PARTNERSHIP
                  </h3>
                  <p>
                    To strengthen partnerships between public and private
                    sectors for sustainable economic development.
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="relative">
                  <div className="absolute w-4 h-4 bg-yellow-500 rounded-full -left-6 top-0"></div>
                </div>
                <div className="pl-8">
                  <h3 className="text-yellow-500 text-2xl font-semibold">
                    EXHIBITION
                  </h3>
                  <p>
                    To showcase innovative solutions and technologies driving
                    trade efficiency and competitiveness in Africa.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        {/* Conference Structure Section */}
        <section className="bg-[#00183A] text-white text-center py-20">
          <h1 className="text-[#F7AE3A] text-5xl md:text-7xl font-bold mb-16">
            CONFERENCE STRUCTURE
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mx-4 md:mx-20">
            <div className="bg-[#F7AE3A] p-10">
              <h2 className="text-[#00183A] text-2xl md:text-3xl font-bold mb-4">
                PLENARY SESSIONS
              </h2>
              <p>
                Keynote addresses, panel discussions, and high-level speeches on
                topics such as regional trade policies, market access, and
                investment opportunities.
              </p>
            </div>
            <div className="bg-[#F7AE3A] p-10">
              <h2 className="text-[#00183A] text-2xl md:text-3xl font-bold mb-4">
                SECTORAL WORKSHOPS
              </h2>
              <p>
                Parallel sessions focusing on specific industries, including
                agriculture, manufacturing, energy, ICT, and services.
              </p>
            </div>
            <div className="bg-[#F7AE3A] p-10">
              <h2 className="text-[#00183A] text-2xl md:text-3xl font-bold mb-4">
                BUSINESS MATCHMAKING
              </h2>
              <p>
                Facilitated sessions for business to business (B2B) meetings,
                matchmaking, and networking, enabling participants to explore
                potential collaborations and partnerships.
              </p>
            </div>
            <div className="bg-[#F7AE3A] p-10">
              <h2 className="text-[#00183A] text-2xl md:text-3xl font-bold mb-4">
                EXHIBITION AREA
              </h2>
              <p>
                Showcase of products, services, and innovations by African
                businesses, startups, and SMEs.
              </p>
            </div>
            <div className="bg-[#F7AE3A] p-10">
              <h2 className="text-[#00183A] text-2xl md:text-3xl font-bold mb-4">
                POLICY DIALOGUES
              </h2>
              <p>
                Roundtable discussions among policymakers, regulators, and
                industry leaders to address regulatory challenges, harmonization
                of trade policies, and implementation strategies for AfCFTA.
              </p>
            </div>
            <div className="bg-[#F7AE3A] p-10">
              <h2 className="text-[#00183A] text-2xl md:text-3xl font-bold mb-4">
                INVESTMENT FORUM
              </h2>
              <p>
                Platform for investors to explore investment opportunities
                across various sectors in African economies, facilitated by
                investment promotion agencies and financial institutions.
              </p>
            </div>
          </div>
        </section>

        {/* Expected Results Section */}
        <section className="bg-[#08285A] text-white text-center relative  ">
          <img
            src={TopImage2}
            alt="Cover"
            className="w-full h-full object-cover absolute mix-blend-overlay"
          />
          <div className="relative z-10 py-20">
            <h1 className="text-white text-5xl md:text-7xl font-bold mb-16">
              EXPECTED RESULTS
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mx-4 md:mx-20">
              <div className="bg-[#F7AE3A] p-10">
                <h2 className="text-blue-900 text-4xl md:text-5xl font-bold mb-4">
                  1
                </h2>
                <p>
                  Enhanced understanding of the benefits and challenges of
                  intra-African trade and AfCFTA implementation.
                </p>
              </div>
              <div className="bg-[#F7AE3A] p-10">
                <h2 className="text-blue-900 text-4xl md:text-5xl font-bold mb-4">
                  2
                </h2>
                <p>
                  Establishment of new business partnerships and investment
                  opportunities.
                </p>
              </div>
              <div className="bg-[#F7AE3A] p-10">
                <h2 className="text-blue-900 text-4xl md:text-5xl font-bold mb-4">
                  3
                </h2>
                <p>
                  Strengthened collaboration between public and private sectors
                  for trade facilitation and economic growth.
                </p>
              </div>
              <div className="bg-[#F7AE3A] p-10">
                <h2 className="text-blue-900 text-4xl md:text-5xl font-bold mb-4">
                  4
                </h2>
                <p>
                  Dissemination of best practices and innovative solutions for
                  enhancing trade competitiveness.
                </p>
              </div>
              <div className="bg-[#F7AE3A] p-10">
                <h2 className="text-blue-900 text-4xl md:text-5xl font-bold mb-4">
                  5
                </h2>
                <p>
                  Increased awareness of regulatory frameworks and policies
                  governing intra-African trade.
                </p>
              </div>
            </div>
          </div>
        </section>

        <footer className="bg-[#00183A] text-white px-4 py-8">
          <div className="container mx-auto">
            {/* <h2 className="text-4xl font-bold text-[#F7AE3A] mb-4">THANK YOU</h2> */}
            <div className="flex space-x-8">
              <div className="text-left">
                <h3 className="text-2xl font-bold mb-2">Contact Us:</h3>
                <p>📞 +2349034444078, +2348188552272</p>
                <p>
                  📧{" "}
                  <a href="mailto:info@theintertradegroup.com">
                    info@theintertradegroup.com
                  </a>
                  ,{" "}
                  <a href="mailto:annehi@intertradegroup.com">
                    annehi@intertradegroup.com
                  </a>
                  ,{" "}
                  <a href="mailto:charles@intertradegroup.com">
                    charles@intertradegroup.com
                  </a>
                </p>
                <p>
                  📍 3rd Floor, The Mall by Funab, First Avenue Gwarimpa, Abuja.
                </p>
                <p>
                  🌐{" "}
                  <a
                    href="https://www.theintertradegroup.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.theintertradegroup.com
                  </a>
                </p>
                <div className="flex flex-row  items-center space-x-4">
                  <a
                    href="https://www.instagram.com/theintertradegroup?igsh=dXphNTZ0N282ajdv"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                  >
                    <i className="fab fa-instagram fa-2x"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=61562724780096&mibextid=ZbWKwL"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    <i className="fab fa-facebook fa-2x"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Home;
